<template>
  <modal
    header-text="Добавление нового элемента"
    button-text="Добавить"
    @action="addNewItem()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label> {{ label }}</label>
      <input v-model="name" type="text" :class="$style.input">
    </div>

    <div :class="$style.inputContainer">
      <label> Категория</label>

      <select v-model="selectedCategory" :class="$style.input">
        <option v-for="option in groups" :key="option.id" :value="option.id">
          {{ option.groupName }}
        </option>
      </select>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Modal from '@/components/common/modal/category-modal.vue';
import { addItem } from '@/api/methods/categories';

Vue.use(VueToast);

export default {
  components: { Modal },
  props: ['groups', 'type', 'apps'],
  data: () => ({
    name: '',
    selectedCategory: undefined,
  }),
  computed: {
    label() {
      if (this.type === 1) return 'Имя exe-файла';
      return 'Ссылка';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async addNewItem() {
      const name = this.name.trim();
      if (name.length > 0) {
        if (this.selectedCategory) {
          const found = this.apps.find((app) => app.name === name);
          if (found) {
            if (this.type === 1) {
              Vue.$toast.open({
                message: 'Программа уже есть в списке',
                type: 'error',
              });
            }
            if (this.type === 2) {
              Vue.$toast.open({
                message: 'Веб-сайт уже есть в списке',
                type: 'error',
              });
            }
          } else {
            const result = await addItem(
              name,
              this.selectedCategory,
              this.type,
            );
            if (result === 'success') this.name = '';
            this.$emit('update');
            this.close();
          }
        } else {
          Vue.$toast.open({
            message: 'Выберите категорию',
            type: 'error',
          });
        }
      } else {
        if (this.type === 1) {
          Vue.$toast.open({
            message: 'Введите имя exe-файла',
            type: 'error',
          });
        }
        if (this.type === 2) {
          Vue.$toast.open({
            message: 'Введите ссылку',
            type: 'error',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 55%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
