<template>
  <modal
    header-text=""
    button-text="Удалить"
    @action="deleteItem"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      {{ output }}
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/category-modal.vue';
import { deleteGroup, deleteItem } from '@/api/methods/categories';

export default {
  components: { Modal },
  props: ['selected', 'type'],
  computed: {
    output() {
      if (this.selected && this.selected.type === 'folder') {
        return 'Вы уверены, что хотите удалить группу?';
      }

      if (this.type === 1) return 'Вы уверены, что хотите удалить программу?';
      return 'Вы уверены, что хотите удалить сайт?';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async deleteItem() {
      if (this.selected.type === 'folder') {
        await deleteGroup(this.selected.id, this.type);
      } else {
        await deleteItem(this.selected.id);
      }
      this.close();
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
</style>
