<template>
  <modal
    header-text="Добавление категории"
    button-text="Добавить"
    @action="addNewItem()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label :class="$style.label"> Название</label>
      <input v-model="name" type="text" :class="$style.input">
    </div>
    <div :class="$style.inputContainer">
      <label :class="$style.label"> Цвет закраски группы в отчетах</label>
      <chrome v-model="colors" :disable-alpha="true" class="colorPicker" />
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { Chrome } from 'vue-color';
import Modal from '@/components/common/modal/category-modal.vue';
import { addGroup } from '@/api/methods/categories';

Vue.use(VueToast);

export default {
  components: { Modal, Chrome },
  props: ['type'],
  data: () => ({
    name: '',
    colors: { r: 255, g: 0, b: 0 },
  }),
  methods: {
    colorToNumber(color) {
      const u8 = new Uint8Array([color.r, color.g, color.b, 0]);
      const u32bytes = u8.buffer.slice(-4);
      const uint = new Uint32Array(u32bytes)[0];
      return uint;
    },
    close() {
      this.$emit('close');
    },
    async addNewItem() {
      const name = this.name.trim();
      if (name.length > 0) {
        const result = await addGroup(
          name,
          this.type,
          this.colorToNumber(this.colors.rgba),
        );
        if (result === 'success') this.name = '';
        this.$emit('update');
        this.close();
      } else {
        Vue.$toast.open({
          message: 'Введите название категории',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 55%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.label {
  margin-right: 10px;
}
</style>

<style lang="scss">
.colorPicker {
  &.vc-chrome {
    width: 55%;
    margin: 10px 5px 0px 0px;
  }
}
</style>

<style>
.category-modal_modalBody_Fng1T {
  padding: 20px 30px 40px 30px;
}
</style>
