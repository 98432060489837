<template>
  <div :class="$style.container">
    <tabset
      class="categoryTabs"
      :tabs="tabs"
      :opened="true"
      :hide-arrow="true"
      :class="$style.tabs"
      :tab-style="tabStyle"
      @tabClick="openNewTab"
    />
    <div :class="$style.container">
      <div :class="$style.gridItemComputersTable">
        <div :class="$style.gridItemComputersTableContent">
          <div :class="$style.buttonsContainer">
            <button
              v-if="tabs[0].active"
              :class="[$style.addUserButton, $style.buttonContainer]"
              @click="openModal('additem')"
            >
              Добавить программу
            </button>
            <button
              v-if="tabs[1].active"
              :class="[$style.addUserButton, $style.buttonContainer]"
              @click="openModal('additem')"
            >
              Добавить веб-сайт
            </button>
            <button
              :class="[$style.addUserButton, $style.buttonContainer]"
              @click="openModal('addGroup')"
            >
              Добавить категорию
            </button>
          </div>
          <div :class="$style.gridItemComputersTableContentTable">
            <dragTreeTable
              v-if="tabs[0].active && appGroups"
              id="appsDragTable"
              :data="treeData"
              :before-drag-over="allowDrag"
              :on-drag="onTreeDataChange"
              :groups="appGroups"
              table-id="appsDragTable"
              @saveNewName="changeName"
              @deleteItem="openDeleteModal"
            />
            <dragTreeTable
              v-if="tabs[1].active && webGroups"
              id="webDragTable"
              :data="treeDataWeb"
              :before-drag-over="allowDrag"
              :on-drag="onTreeDataChange"
              :groups="webGroups"
              table-id="webDragTable"
              @saveNewName="changeName"
              @deleteItem="openDeleteModal"
            />
          </div>
        </div>
      </div>
    </div>
    <delete-modal
      id="deleteCategory"
      :class="$style.modal"
      :selected="selectedItem"
      :type="activeTab.type"
      @close="closeModal('deleteCategory')"
      @update="fetch()"
    />
    <add-item
      id="additem"
      :class="$style.modal"
      :groups="selectedGroups"
      :apps="selectedApps"
      :type="activeTab.type"
      @close="closeModal('additem')"
      @update="fetch()"
    />
    <add-group
      id="addGroup"
      :class="$style.modal"
      :type="activeTab.type"
      @close="closeModal('addGroup')"
      @update="fetch()"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import dragTreeTable from '@/components/pages/agents/drag-tree-table/dragTreeTable.vue';
import {
  getCategoryList,
  renameGroup,
  renameItem,
  changeGroup,
  changeColor,
} from '@/api/methods/categories';
import DeleteModal from '@/views/settings/categories/delete-category.vue';
import AddItem from '@/views/settings/categories/add-item.vue';
import AddGroup from '@/views/settings/categories/add-group.vue';

Vue.use(VueToast);

export default {
  components: {
    Tabset,
    dragTreeTable,
    DeleteModal,
    AddItem,
    AddGroup,
  },
  data: () => ({
    openState: {},
    tabs: [
      {
        type: 1,
        title: 'Программы',
        active: true,
      },
      {
        type: 2,
        title: 'Посещенные сайты',
        active: false,
      },
    ],
    tabStyle: {
      fontSize: '15px',
    },
    treeData: {
      columns: [
        {
          type: 'selection',
          title: 'Название',
          field: 'title',
          align: 'left',
          flex: 3,
        },
        {
          type: 'color',
          title: 'Цвет',
          field: 'color',
          maxWidth: '25%',
          align: 'left',
          flex: 2,
          alignSelf: 'auto',
        },
        {
          type: 'action',
          title: 'Действия',
          maxWidth: '15%',
          align: 'center',
          flex: 1,
          actions: [
            {
              text: 'изменить',
              eventName: 'changeName',
              formatter: () => '',
            },
            {
              text: 'сохранить',
              eventName: 'saveNewName',
              formatter: () => '',
            },
            {
              text: 'удалить',
              eventName: 'deleteItem',
              formatter: () => '',
            },
            {
              text: 'отменить',
              eventName: 'cancelChange',
              formatter: () => '',
            },
          ],
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'parentId',
      },
    },
    treeDataWeb: {
      columns: [
        {
          type: 'selection',
          title: 'Название',
          field: 'title',
          align: 'left',
          flex: 3,
        },
        {
          type: 'color',
          title: 'Цвет',
          field: 'color',
          maxWidth: '25%',
          align: 'left',
          flex: 2,
        },
        {
          type: 'action',
          title: 'Действия',
          maxWidth: '15%',
          align: 'center',
          flex: 1,
          actions: [
            {
              text: 'изменить',
              eventName: 'changeName',
              formatter: () => '',
            },
            {
              text: 'сохранить',
              eventName: 'saveNewName',
              formatter: () => '',
            },
            {
              text: 'удалить',
              eventName: 'deleteItem',
              formatter: () => '',
            },
            {
              text: 'отменить',
              eventName: 'cancelChange',
              formatter: () => '',
            },
          ],
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'parentId',
      },
    },
    apps: [],
    sites: [],
    selectedItem: undefined,
    appGroups: null,
    webGroups: null,
  }),
  computed: {
    activeTab() {
      const tab = this.tabs.find((item) => item.active === true);
      return tab;
    },
    selectedGroups() {
      if (this.activeTab.type === 1) return this.appGroups;
      return this.webGroups;
    },
    selectedApps() {
      if (this.activeTab.type === 1) return this.apps;
      return this.sites;
    },
  },
  async created() {
    await this.fetch();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/refresh') {
          await this.fetch();
        }
      },
    });
  },
  methods: {
    async onTreeDataChange(list, computer, group, target) {
      if (target !== 'center') {
        Vue.$toast.open({
          message: 'Элемент может быть перемещен только внутрь категории',
          type: 'error',
        });
      } else {
        await changeGroup(computer.id, group.id);

        if (this.activeTab.type === 1) this.treeData.lists = list;
        else this.treeDataWeb.lists = list;

        // await this.fetch();
      }
    },
    allowDrag(row, row2) {
      if (row2.type === 'item' || row.type === 'folder') return false;
      return true;
    },
    openDeleteModal(model) {
      this.selectedItem = model;
      this.openModal('deleteCategory');
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async changeName(model) {
      if (model.title !== model.name) {
        if (model.type === 'item') await renameItem(model.id, model.title);
        else await renameGroup(model.id, model.title);
      }
      if (model.type === 'folder' && model.color !== model.initialColor) {
        await changeColor(model.id, model.color);
        await this.fetch();
      }
    },
    setNodeAttributes(node) {
      node.ident = node.id + node.name;
      node.title = node.name;
      if (node.type === 'folder') {
        node.opened = true;
        node.isContainChildren = true;
        node.initialColor = node.color;
        node.children.forEach((child) => this.setNodeAttributes(child));
        if (this.openState[node.id]) node.open = this.openState[node.id];
      } else {
        node.opened = false;
        node.dropDisabled = true;
      }
    },
    async fetch() {
      if (this.treeData.lists) {
        this.openState = {};
        this.treeData.lists.forEach((category) => {
          this.openState[category.id] = category.open;
        });
        this.treeDataWeb.lists.forEach((category) => {
          this.openState[category.id] = category.open;
        });
      }
      this.promise = await getCategoryList().then(({ data }) => {
        if (data) {
          data.result.resApps.forEach((item) => this.setNodeAttributes(item));
          this.apps = data.result.apps;
          this.treeData.lists = data.result.resApps;
          this.appGroups = data.result.groups;

          data.result.resWeb.forEach((item) => this.setNodeAttributes(item));
          this.sites = data.result.sites;
          this.treeDataWeb.lists = data.result.resWeb;
          this.webGroups = data.result.groupsWeb;
        }
      });
    },
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
  },
};
</script>

<style lang="scss" module>
.tabs {
  margin: 10px;
}

.container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.gridItemComputersTable {
  flex: 1 1 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.gridItemComputersTableContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.buttonsContainer {
  display: inline-flex;
  margin: 0px 10px;
}

.addUserButton {
  border: 1px solid white;
  background: #2985bf;
  cursor: pointer;
  height: 30px;
  color: white;
  width: 100%;
}

.gridItemComputersTableContentTable {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  height: 100%;
}

.modal {
  display: none;
  z-index: 3;
}
</style>

<style>
.drag-tree-table {
  margin: 0;
  color: black;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.drag-tree-table-header {
  background: #f5f5f5;
  line-height: 20px;
}

.tree-row {
  line-height: 20px;
}
</style>
